export default [
    {
        borderWidth: 1,
        backgroundColor: 'rgba(255, 0, 52, 0.4)',
        borderColor: 'rgba(255, 0, 52, 1)',
        hoverBackgroundColor: 'rgba(255, 0, 52, 0.6)',
        hoverBorderColor: 'rgba(255, 0, 52, 1)',
    },
    {
        borderWidth: 1,
        backgroundColor: 'rgba(255, 176, 0, 0.4)',
        borderColor: 'rgba(255, 176, 0, 1)',
        hoverBackgroundColor: 'rgba(255, 176, 0,0.6)',
        hoverBorderColor: 'rgba(255, 176, 0,1)',
    },
    {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 145, 232, 0.4)',
        borderColor: 'rgba(0, 145, 232, 1)',
        hoverBackgroundColor: 'rgba(0, 145, 232, 0.6)',
        hoverBorderColor: 'rgba(0, 145, 232, 1)',
    },
    {
        borderWidth: 1,
        backgroundColor: 'rgba(87, 0, 249, 0.4)',
        borderColor: 'rgba(87, 0, 249, 1)',
        hoverBackgroundColor: 'rgba(87, 0, 249, 0.6)',
        hoverBorderColor: 'rgba(87, 0, 249, 1)',
    },
    {
        borderWidth: 1,
        backgroundColor: 'rgba(171, 172, 174, 0.4)',
        borderColor: 'rgba(171, 172, 174, 1)',
        hoverBackgroundColor: 'rgba(171, 172, 174, 0.6)',
        hoverBorderColor: 'rgba(171, 172, 174, 1)',
    },
    {
        borderWidth: 1,
        backgroundColor: 'rgba(255, 0, 122, 0.4)',
        borderColor: 'rgba(255, 0, 122, 1)',
        hoverBackgroundColor: 'rgba(255, 0, 122, 0.6)',
        hoverBorderColor: 'rgba(255, 0, 122, 1)',
    },
    {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 224, 255, 0.4)',
        borderColor: 'rgba(0, 224, 255, 1)',
        hoverBackgroundColor: 'rgba(0, 224, 255, 0.6)',
        hoverBorderColor: 'rgba(0, 224, 255, 1)',
    },
    {
        borderWidth: 1,
        backgroundColor: 'rgba(143, 255, 0, 0.4)',
        borderColor: 'rgba(143, 255, 0, 1)',
        hoverBackgroundColor: 'rgba(143, 255, 0, 0.6)',
        hoverBorderColor: 'rgba(143, 255, 0, 1)',
    },
    {
        borderWidth: 1,
        backgroundColor: 'rgba(255, 0, 52, 0.4)',
        borderColor: 'rgba(255, 0, 52, 1)',
        hoverBackgroundColor: 'rgba(255, 0, 52, 0.6)',
        hoverBorderColor: 'rgba(255, 0, 52, 1)',
    },
    {
        borderWidth: 1,
        backgroundColor: 'rgba(255, 176, 0, 0.4)',
        borderColor: 'rgba(255, 176, 0, 1)',
        hoverBackgroundColor: 'rgba(255, 176, 0,0.6)',
        hoverBorderColor: 'rgba(255, 176, 0,1)',
    },
    {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 145, 232, 0.4)',
        borderColor: 'rgba(0, 145, 232, 1)',
        hoverBackgroundColor: 'rgba(0, 145, 232, 0.6)',
        hoverBorderColor: 'rgba(0, 145, 232, 1)',
    },
    {
        borderWidth: 1,
        backgroundColor: 'rgba(87, 0, 249, 0.4)',
        borderColor: 'rgba(87, 0, 249, 1)',
        hoverBackgroundColor: 'rgba(87, 0, 249, 0.6)',
        hoverBorderColor: 'rgba(87, 0, 249, 1)',
    },
    {
        borderWidth: 1,
        backgroundColor: 'rgba(171, 172, 174, 0.4)',
        borderColor: 'rgba(171, 172, 174, 1)',
        hoverBackgroundColor: 'rgba(171, 172, 174, 0.6)',
        hoverBorderColor: 'rgba(171, 172, 174, 1)',
    },
    {
        borderWidth: 1,
        backgroundColor: 'rgba(255, 0, 122, 0.4)',
        borderColor: 'rgba(255, 0, 122, 1)',
        hoverBackgroundColor: 'rgba(255, 0, 122, 0.6)',
        hoverBorderColor: 'rgba(255, 0, 122, 1)',
    },
    {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 224, 255, 0.4)',
        borderColor: 'rgba(0, 224, 255, 1)',
        hoverBackgroundColor: 'rgba(0, 224, 255, 0.6)',
        hoverBorderColor: 'rgba(0, 224, 255, 1)',
    },
    {
        borderWidth: 1,
        backgroundColor: 'rgba(143, 255, 0, 0.4)',
        borderColor: 'rgba(143, 255, 0, 1)',
        hoverBackgroundColor: 'rgba(143, 255, 0, 0.6)',
        hoverBorderColor: 'rgba(143, 255, 0, 1)',
    },
    {
        borderWidth: 1,
        backgroundColor: 'rgba(255, 0, 52, 0.4)',
        borderColor: 'rgba(255, 0, 52, 1)',
        hoverBackgroundColor: 'rgba(255, 0, 52, 0.6)',
        hoverBorderColor: 'rgba(255, 0, 52, 1)',
    },
    {
        borderWidth: 1,
        backgroundColor: 'rgba(255, 176, 0, 0.4)',
        borderColor: 'rgba(255, 176, 0, 1)',
        hoverBackgroundColor: 'rgba(255, 176, 0,0.6)',
        hoverBorderColor: 'rgba(255, 176, 0,1)',
    },
    {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 145, 232, 0.4)',
        borderColor: 'rgba(0, 145, 232, 1)',
        hoverBackgroundColor: 'rgba(0, 145, 232, 0.6)',
        hoverBorderColor: 'rgba(0, 145, 232, 1)',
    },
    {
        borderWidth: 1,
        backgroundColor: 'rgba(87, 0, 249, 0.4)',
        borderColor: 'rgba(87, 0, 249, 1)',
        hoverBackgroundColor: 'rgba(87, 0, 249, 0.6)',
        hoverBorderColor: 'rgba(87, 0, 249, 1)',
    },
    {
        borderWidth: 1,
        backgroundColor: 'rgba(171, 172, 174, 0.4)',
        borderColor: 'rgba(171, 172, 174, 1)',
        hoverBackgroundColor: 'rgba(171, 172, 174, 0.6)',
        hoverBorderColor: 'rgba(171, 172, 174, 1)',
    },
    {
        borderWidth: 1,
        backgroundColor: 'rgba(255, 0, 122, 0.4)',
        borderColor: 'rgba(255, 0, 122, 1)',
        hoverBackgroundColor: 'rgba(255, 0, 122, 0.6)',
        hoverBorderColor: 'rgba(255, 0, 122, 1)',
    },
    {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 224, 255, 0.4)',
        borderColor: 'rgba(0, 224, 255, 1)',
        hoverBackgroundColor: 'rgba(0, 224, 255, 0.6)',
        hoverBorderColor: 'rgba(0, 224, 255, 1)',
    },
    {
        borderWidth: 1,
        backgroundColor: 'rgba(143, 255, 0, 0.4)',
        borderColor: 'rgba(143, 255, 0, 1)',
        hoverBackgroundColor: 'rgba(143, 255, 0, 0.6)',
        hoverBorderColor: 'rgba(143, 255, 0, 1)',
    },
    {
        borderWidth: 1,
        backgroundColor: 'rgba(255, 0, 52, 0.4)',
        borderColor: 'rgba(255, 0, 52, 1)',
        hoverBackgroundColor: 'rgba(255, 0, 52, 0.6)',
        hoverBorderColor: 'rgba(255, 0, 52, 1)',
    },
    {
        borderWidth: 1,
        backgroundColor: 'rgba(255, 176, 0, 0.4)',
        borderColor: 'rgba(255, 176, 0, 1)',
        hoverBackgroundColor: 'rgba(255, 176, 0,0.6)',
        hoverBorderColor: 'rgba(255, 176, 0,1)',
    },
    {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 145, 232, 0.4)',
        borderColor: 'rgba(0, 145, 232, 1)',
        hoverBackgroundColor: 'rgba(0, 145, 232, 0.6)',
        hoverBorderColor: 'rgba(0, 145, 232, 1)',
    },
    {
        borderWidth: 1,
        backgroundColor: 'rgba(87, 0, 249, 0.4)',
        borderColor: 'rgba(87, 0, 249, 1)',
        hoverBackgroundColor: 'rgba(87, 0, 249, 0.6)',
        hoverBorderColor: 'rgba(87, 0, 249, 1)',
    },
    {
        borderWidth: 1,
        backgroundColor: 'rgba(171, 172, 174, 0.4)',
        borderColor: 'rgba(171, 172, 174, 1)',
        hoverBackgroundColor: 'rgba(171, 172, 174, 0.6)',
        hoverBorderColor: 'rgba(171, 172, 174, 1)',
    },
    {
        borderWidth: 1,
        backgroundColor: 'rgba(255, 0, 122, 0.4)',
        borderColor: 'rgba(255, 0, 122, 1)',
        hoverBackgroundColor: 'rgba(255, 0, 122, 0.6)',
        hoverBorderColor: 'rgba(255, 0, 122, 1)',
    },
    {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 224, 255, 0.4)',
        borderColor: 'rgba(0, 224, 255, 1)',
        hoverBackgroundColor: 'rgba(0, 224, 255, 0.6)',
        hoverBorderColor: 'rgba(0, 224, 255, 1)',
    },
    {
        borderWidth: 1,
        backgroundColor: 'rgba(143, 255, 0, 0.4)',
        borderColor: 'rgba(143, 255, 0, 1)',
        hoverBackgroundColor: 'rgba(143, 255, 0, 0.6)',
        hoverBorderColor: 'rgba(143, 255, 0, 1)',
    },
];